import {
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider as ChakraDivider,
  DividerProps,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Spinner,
  Stack,
  Tag,
  Text,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import {
  CaseClient,
  CaseRequestClient,
  ICase,
  ICaseExtended,
  ICompanyEnums,
  IIntegrationConnection,
  ISsnPerson,
  IUser,
  UpdateCaseType,
} from 'kach-clients'
import { buildTestId, translator } from 'kach-commons'
import Link from 'next/link'
import React, { useCallback, useContext, useMemo } from 'react'
import { FaExchangeAlt } from 'react-icons/fa'
import { isExternalDialogOpenAtom } from '../atoms/is-external-dialog-open.atom'
import { isExternalMenuOpenAtom } from '../atoms/is-external-menu-open.atom'
import { CASES_QUERY_KEY } from '../constants/query-keys'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { KachContext } from '../providers/KachProvider'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { buildUserName } from '../utils/build-user-name'
import { getLabelByValue } from '../utils/case-results'
import { formatDate } from '../utils/format-date'
import { hasPermission } from '../utils/has-permission'
import { CaseAnnotations } from './CaseAnnotations'
import { CaseArtifacts } from './CaseArtifacts'
import { CaseClaims } from './CaseClaims'
import { CaseCustomer } from './CaseCustomer'
import { CaseCustomerReports } from './CaseCustomerReports'
import { CaseDetailsSsn } from './CaseDetailSsn'
import { CaseEnumSelect } from './CaseEnumSelect'
import { CaseExpertExaminations } from './CaseExpertExaminations'
import { CaseInspections } from './CaseInspections'
import {
  ActiveInvestigationsAlert,
  CaseInvestigations,
} from './CaseInvestigations'
import { CaseMails } from './CaseMails'
import { CaseNews } from './CaseNews'
import { CaseReportInspections } from './CaseReportInspections'
import { CaseSavings } from './CaseSavings'
import { CaseSinisterDocuments } from './CaseSinisterDocuments'
import { CaseSuspectsAlert } from './CaseSuspectsAlert'
import { CaseThreads } from './CaseThreads'
import { CaseTimeline } from './CaseTimeline'
import { CaseViews } from './CaseViews'
import { CloseCaseBtn } from './CloseCaseBtn'
import { CollapsibleContent } from './CollapsibleContent'
import CommentInputCase from './CommentInputCase'
import { CrawlerJobProgress, ResyncButton } from './CrawlerJobProgress'
import { IDynamicObject } from './InvestigationLine'
import { LabelValueDisplayer } from './LabelValueDisplayer'
import { ManageCaseUsers, UsersListSelectSearcher } from './ManageCaseUsers'
import { NewLineText } from './NewLineText'
import { CaseNotes } from './Notepad'
import { NavigatorFC } from './NotificationList'
import { ProcessorDisplayer } from './ProcessorDisplayer'
import { ReopenCaseBtn } from './ReopenCaseBtn'
import { TakeCaseBtn } from './TakeCaseBtn'
import { UserDisplayer } from './UserDisplayer'

interface CaseDetailContextProps extends ICaseExtended {}

const CaseDetailContext = React.createContext<
  CaseDetailContextProps | undefined
>(undefined)

export const useCaseDetail = () => React.useContext(CaseDetailContext)!

export const CaseDetailProvider = ({
  children,
  data,
}: {
  children: React.ReactNode
  data: ICaseExtended
}) => {
  return (
    <CaseDetailContext.Provider value={data}>
      {children}
    </CaseDetailContext.Provider>
  )
}

export interface ICaseDetailProps {
  ExtraAttributes?: React.FC<{ case: ICase }>
  withStickyPos?: boolean
  query: IDynamicObject
  integrationConnections: IIntegrationConnection[]
  isLoading: boolean
  case: ICaseExtended
  id: number
  column?: boolean
  isPreview?: boolean
  enums?: ICompanyEnums
  Navigator: NavigatorFC
  SectionsExtended?: React.ReactNode
  renderCallToActions?: React.ReactNode
  renderPrevCollapse?: React.ReactNode
}

export interface ISsnPersons {
  persons: ISsnPerson[]
}

const Divider = (props: DividerProps) => {
  const isExternalDialogOpen = useAtomValue(isExternalDialogOpenAtom)

  if (isExternalDialogOpen) {
    return null
  }

  return <ChakraDivider {...props} />
}

export const CaseDetail = (props: ICaseDetailProps) => {
  const auth = useAuth()

  const toast = useToast()

  const error = useError()

  const {
    isOpen: isOpenResponsiblePopover,
    onToggle: onToggleResponsiblePopover,
  } = useDisclosure()

  const caseId = props.case?.id

  const hasMiddleDivider =
    useBreakpointValue({
      base: false,
      sm: false,
      md: false,
      lg: false,
      xl: true,
    }) || props.isPreview

  const parsePolicy = (c: ICaseExtended) => {
    if (c.caseRequest?.policyAgency) {
      return `${c.caseRequest.policyAgency} - ${c.caseRequest.policyId}`
    }

    return c.caseRequest.policyId
  }

  const isCaseMember = props.case?.users?.some(
    (user) => user?.user?.id === auth.id,
  )

  const canReadAllCases = auth.role.permissions.some(
    (p) => p.action === 'update_all' && p.resource === 'case',
  )

  const isAdmin = hasPermission(auth?.role?.permissions, {
    action: 'update_all',
    resource: 'case',
  })

  const hasRelationWithCase = !!canReadAllCases || !!isCaseMember

  const responsible = useMemo(
    () =>
      props.case?.users?.find((caseUser) => caseUser.type === 'responsible'),
    [props.case],
  )

  const job = props.case?.jobs?.find((job) => job.type === 'case_crawler')

  const collaborators = useMemo(
    () =>
      props.case?.users?.filter(
        (caseUser) => caseUser.type === 'collaborator',
      ) || [],
    [props.case],
  )

  const onDelegateResponsibility = useCallback(
    (input: { user: IUser; caseId: number }) =>
      CaseClient.delegateResponsibility(input.caseId, input.user.id),
    [],
  )

  const onUpdateCase = useCallback(
    (update: UpdateCaseType) => {
      if (caseId) {
        toast.promise(CaseClient.updateCase(caseId, update), {
          loading: {
            title: 'Actualizando caso...',
          },
          success: () => {
            queryClient.invalidateQueries([buildCaseQueryKey(caseId)])
            queryClient.invalidateQueries([CASES_QUERY_KEY])
            return { title: 'Caso actualizado correctamente' }
          },
          error: (err) => {
            return { title: error.getErrorMessage(err) }
          },
        })
      }
    },
    [caseId],
  )

  const onUpdateRequestCase = useCallback(
    (update: UpdateCaseType) => {
      if (caseId) {
        toast.promise(CaseRequestClient.update(caseId, update), {
          loading: {
            title: 'Actualizando solicitud...',
          },
          success: () => {
            queryClient.invalidateQueries([buildCaseQueryKey(caseId)])
            queryClient.invalidateQueries([CASES_QUERY_KEY])
            return { title: 'Solicitud actualizada correctamente' }
          },
          error: (err) => {
            return { title: error.getErrorMessage(err) }
          },
        })
      }
    },
    [caseId],
  )

  const { getErrorMessage } = useError()

  const queryClient = useQueryClientSingleton()

  const context = useContext(KachContext)

  const mutateResponsible = useMutation(onDelegateResponsibility, {
    onMutate: async ({ caseId, user }) => {
      const queryKey = buildCaseQueryKey(caseId)

      await queryClient.cancelQueries([queryKey])

      const snapshot = queryClient.getQueryData([queryKey])

      queryClient.setQueryData<ICaseExtended | undefined>(
        [queryKey],
        //@ts-ignore
        (casePrev) => {
          if (!casePrev) {
            return casePrev
          }

          return {
            ...casePrev,
            users: [
              ...casePrev.users.filter((user) => user.type !== 'responsible'),
              {
                type: 'responsible',
                user,
              },
            ],
          }
        },
      )

      return {
        snapshot,
        queryKey,
      }
    },
    onError: (_, __, context) => {
      //@ts-ignore
      queryClient.setQueryData([context?.queryKey], context?.snapshot)
    },
    onSettled: (_, __, ___, context) => {
      //@ts-ignore
      queryClient.invalidateQueries([context?.queryKey])
    },
    context: context.defaultContext,
  })

  const isCurrentUserIncluded = (props.case?.users || []).find(
    (caseUser) => caseUser?.user?.id === auth.id,
  )

  const [isExternalDialogOpen] = useAtom(isExternalDialogOpenAtom)

  const [isExternalMenuOpen] = useAtom(isExternalMenuOpenAtom)

  const canUpdateAllCases = auth.role.permissions.some(
    (p) => p.action === 'update_all' && p.resource === 'case',
  )

  const caseIsOpen = props.case?.status !== 'closed'
  const caseIsClosed = props.case?.status === 'closed'

  const canReopenCase =
    caseIsClosed &&
    (isCurrentUserIncluded?.type === 'responsible' || canUpdateAllCases)

  const canCloseCase =
    caseIsOpen &&
    (isCurrentUserIncluded?.type === 'responsible' || canUpdateAllCases)

  const canUpdateSaving =
    !!hasRelationWithCase &&
    ['in_progress', 'closed'].includes(props.case?.status)

  const canPerformActions =
    !!hasRelationWithCase && props.case?.status === 'in_progress'

  const canUpdateSinisterType =
    canUpdateAllCases && props.case?.status === 'in_progress'

  if (props.isLoading || !props.case) {
    return (
      <Box display='flex' justifyContent='center'>
        <Spinner size='md' />
      </Box>
    )
  }

  const separatorStyles = {
    ...(hasMiddleDivider && {
      content: `""`,
      position: 'absolute',
      height: '100%',
      width: '1px',
      right: '-30px',
      top: '6px',
      bg: 'brand.50',
    }),
  }

  const inspectionResource =
    auth.company.type === 'lab' ? 'investigation' : 'case'

  return (
    <CaseDetailProvider data={props.case}>
      <Box display='flow-root'>
        <Stack mb='2' direction='column' spacing={2}>
          <ActiveInvestigationsAlert caseId={props.case.id} />
          <CaseSuspectsAlert data={props.case} />
          {job && (
            <Box mb='5'>
              <CrawlerJobProgress
                job={job}
                boxProps={{
                  display: 'flex',
                  flexDirection: props.isPreview
                    ? 'column'
                    : ['column', 'column', 'column', 'row', 'row'],
                  justifyContent: 'space-between',
                  alignItems: props.isPreview
                    ? 'end'
                    : ['end', 'end', 'end', 'center', 'center'],
                  width: '100%',
                }}
              >
                {!!hasRelationWithCase &&
                  job.status !== 'pending' &&
                  job.status !== 'running' && (
                    <ResyncButton
                      buttonProps={{
                        marginTop: ['2', '2', '2', '0', '0'],
                      }}
                      caseId={props.case.id}
                    />
                  )}
              </CrawlerJobProgress>
            </Box>
          )}
        </Stack>

        {!props.isPreview && (
          <Breadcrumb pl={2} fontSize='sm'>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} href='/cases'>
                Casos
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href='#'>Detalle de caso</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        )}
        <Box
          pb={props.isPreview ? '12' : 0}
          display='flex'
          flexDir={
            props.column ? ['column', null, null, null, 'row', 'row'] : 'column'
          }
          experimental_spaceX={props.column ? [0, null, null, null, 12, 12] : 0}
          alignItems='start'
        >
          <Box
            w={props.column ? ['full', null, null, null, '65%', '70%'] : 'full'}
            position='sticky'
            zIndex={isExternalMenuOpen || isExternalDialogOpen ? '0' : '2'}
            p={2}
            pr={0}
            _before={separatorStyles}
          >
            <Box>
              <Box
                display={'flex'}
                flexDir={props.isPreview ? 'column' : 'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box
                  w='full'
                  display='flex'
                  flexDir={'column'}
                  alignItems={'start'}
                  experimental_spaceY={1}
                >
                  <Text fontWeight='bold' textTransform='uppercase'>
                    Caso #{props.case.caseRequest.sinisterId}
                  </Text>
                  <Box display='flex' experimental_spaceX={4}>
                    <Tag size='sm' colorScheme='gray' variant='outline'>
                      {props.case.caseRequest.sinisterType?.name}
                    </Tag>
                    <Tag size='sm' colorScheme='gray' variant='outline'>
                      {props.case.caseRequest.reason?.name}
                    </Tag>
                    <Tag size='sm' colorScheme='gray' variant='outline'>
                      {props.case.instance?.name}
                    </Tag>
                  </Box>
                </Box>
                {auth.company.type === 'insurer' && (
                  <Box
                    display='flex'
                    width={props.isPreview ? '100%' : 'initial'}
                    alignItems='center'
                    justifySelf={'end'}
                    experimental_spaceX={4}
                    mt={props.isPreview ? 4 : [4, null, null, 0, 0, 0]}
                  >
                    {caseIsOpen && (
                      <TakeCaseBtn
                        status={props.case.status}
                        caseId={props.id}
                      />
                    )}

                    {(caseIsOpen || canPerformActions) && (
                      <Stack direction={'row'} alignItems='center' spacing={4}>
                        {props.renderCallToActions}
                      </Stack>
                    )}
                  </Box>
                )}
              </Box>
              <Divider my={4} />
              <Box display='flex' flexDir='column' experimental_spaceY={4}>
                <Box
                  fontStyle='italic'
                  fontSize='xs'
                  color='gray.500'
                  bg='gray.50'
                  p={4}
                  borderRadius='0.2rem'
                >
                  <NewLineText>{`${props.case.caseRequest?.description}`}</NewLineText>
                </Box>
                {auth.company.type === 'insurer' && (
                  <Box
                    display='flex'
                    flexDir={
                      props.isPreview
                        ? 'row'
                        : ['row', null, null, 'row', 'row', 'row']
                    }
                    justifyContent='space-between'
                    alignItems={props.isPreview ? 'start' : 'start'}
                  >
                    <Box
                      display={'flex'}
                      flexDir={
                        props.isPreview
                          ? 'column'
                          : ['column', null, null, 'row', 'row', 'row']
                      }
                      gap={6}
                      alignItems={'start'}
                    >
                      <ProcessorDisplayer case={props.case} />
                      {responsible ? (
                        <Box
                          display='flex'
                          alignItems='center'
                          experimental_spaceX={2}
                          {...buildTestId(
                            `case-responsible-${responsible.user.email}-tooltip`,
                          )}
                          position={'relative'}
                        >
                          <UserDisplayer
                            user={responsible.user}
                            avatarSize='md'
                            title='Responsable de fraude'
                          />
                          {responsible.user.id === auth.id &&
                            canPerformActions &&
                            hasPermission(auth?.role?.permissions, {
                              action: 'assign',
                              resource: 'case',
                            }) && (
                              <Popover
                                isLazy
                                isOpen={isOpenResponsiblePopover}
                                onClose={onToggleResponsiblePopover}
                              >
                                {({ onClose }) => {
                                  return (
                                    <>
                                      <PopoverTrigger>
                                        <Tooltip label='Asignar responsable'>
                                          <Button
                                            size='xs'
                                            variant={'unstyled'}
                                            right={'8'}
                                            top={'-3'}
                                            onClick={onToggleResponsiblePopover}
                                          >
                                            <Icon as={FaExchangeAlt} />
                                          </Button>
                                        </Tooltip>
                                      </PopoverTrigger>
                                      <PopoverContent>
                                        <PopoverArrow />
                                        <PopoverCloseButton />
                                        <PopoverHeader>
                                          Responsable
                                        </PopoverHeader>
                                        <PopoverBody>
                                          <Box>
                                            <UsersListSelectSearcher
                                              singular
                                              onClickUser={(user) => {
                                                mutateResponsible.mutate({
                                                  caseId: props.id,
                                                  user,
                                                })
                                                onClose()
                                              }}
                                              filterUsers={(users) =>
                                                users.filter(
                                                  (user) =>
                                                    user.id !==
                                                    responsible.user.id,
                                                )
                                              }
                                            />
                                          </Box>
                                        </PopoverBody>
                                      </PopoverContent>
                                    </>
                                  )
                                }}
                              </Popover>
                            )}
                        </Box>
                      ) : (
                        <Text fontStyle={'italic'}>No hay responsable</Text>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Divider my={4} />
            <Box display='flex' flexDir='column' experimental_spaceY={4}>
              <Box display={'flex'} flexDir={'column'} experimental_spaceY={2}>
                <Text fontWeight='bold' fontSize={'lg'}>
                  Datos generales del caso
                </Text>
                <LabelValueDisplayer label='Tipo de caso'>
                  <Text>
                    {translator(`caseType.${props.case.caseRequest.caseType}`)}
                  </Text>
                </LabelValueDisplayer>
                <LabelValueDisplayer label='Estado'>
                  <CaseEnumSelect
                    readonly={!canPerformActions}
                    enumKey='externalStatus'
                    caseExtended={props.case}
                    onUpdateCase={onUpdateCase}
                  />
                </LabelValueDisplayer>
                <LabelValueDisplayer label='Tipo de siniestro'>
                  <CaseEnumSelect
                    enumKey={'sinisterType'}
                    caseExtended={props.case}
                    onUpdateCase={onUpdateRequestCase}
                    readonly={!canUpdateSinisterType}
                    additonalValidations
                  />
                </LabelValueDisplayer>

                <LabelValueDisplayer label='Motivo'>
                  <CaseEnumSelect
                    enumKey={'reason'}
                    caseExtended={props.case}
                    readonly={!canPerformActions}
                    onUpdateCase={onUpdateRequestCase}
                    additonalValidations
                  />
                </LabelValueDisplayer>

                {props.ExtraAttributes && (
                  <props.ExtraAttributes case={props.case} />
                )}
                {props.case.status !== 'not_assigned' && (
                  <LabelValueDisplayer label='Colaboradores'>
                    <Box
                      display='flex'
                      alignItems='center'
                      experimental_spaceX={2}
                    >
                      {collaborators.length ? (
                        collaborators.map((collaborator, index) => {
                          return (
                            <Tooltip
                              key={index}
                              label={buildUserName(collaborator.user)}
                            >
                              <Avatar
                                {...buildTestId('case-collaborator-avatar')}
                                referrerPolicy='no-referrer'
                                name={buildUserName(collaborator.user)}
                                src={collaborator.user.profilePhoto}
                                size='xs'
                                bg='primary'
                              />
                            </Tooltip>
                          )
                        })
                      ) : (
                        <Text>Sin colaboradores</Text>
                      )}

                      {(isAdmin ||
                        responsible?.user.id === auth.id ||
                        canPerformActions) && (
                        <ManageCaseUsers
                          caseId={caseId}
                          users={props.case.users}
                        />
                      )}
                    </Box>
                  </LabelValueDisplayer>
                )}

                {auth.company.type === 'insurer' && (
                  <>
                    <LabelValueDisplayer label='Visualizaciones'>
                      <CaseViews caseId={caseId} />
                    </LabelValueDisplayer>
                    <LabelValueDisplayer label=''>
                      <Box mr={4}>
                        <CaseNotes caseId={props.case.id} />
                      </Box>
                      <Box
                        display='flex'
                        alignItems='center'
                        experimental_spaceX={4}
                        mt={props.isPreview ? 0 : [4, null, null, 0, 0, 0]}
                      >
                        {canReopenCase && <ReopenCaseBtn case={props.case} />}

                        {canCloseCase && (
                          <CloseCaseBtn
                            case={props.case}
                            companyEnums={props.enums}
                            boxStyles={{ ml: !props.isPreview ? 44 : 4 }}
                          />
                        )}
                      </Box>
                    </LabelValueDisplayer>
                  </>
                )}
              </Box>
              <Box>
                <Text fontWeight='bold' fontSize={'lg'} mb={2}>
                  Propiedades
                </Text>
                <SimpleGrid
                  columns={props.isPreview ? 1 : [1, null, null, null, 2]}
                  spacing={4}
                  alignItems={'start'}
                >
                  <CollapsibleContent title='Siniestro'>
                    <LabelValueDisplayer label='Número'>
                      <Text>{props.case.caseRequest?.sinisterId}</Text>
                    </LabelValueDisplayer>
                    <LabelValueDisplayer
                      label='Estado'
                      hide={!props.case.caseRequest.sinisterStatus}
                    >
                      <Text>{props.case.caseRequest.sinisterStatus}</Text>
                    </LabelValueDisplayer>
                    {caseIsClosed && (
                      <LabelValueDisplayer label='Resultado'>
                        <Text {...buildTestId('case-detail-result')}>
                          {getLabelByValue(props.case.result)}
                        </Text>
                      </LabelValueDisplayer>
                    )}

                    <LabelValueDisplayer
                      label='Fecha Siniestro'
                      hide={!props.case.caseRequest.sinisterDate}
                    >
                      <Text>
                        {formatDate(props.case.caseRequest.sinisterDate)}
                      </Text>
                    </LabelValueDisplayer>
                    {caseIsClosed && (
                      <LabelValueDisplayer label='Monto ahorrado'>
                        <Text {...buildTestId('case-detail-denounced-amount')}>
                          {/* @ts-ignore */}
                          {props.case.closeMotive?.denouncedAmount}
                        </Text>
                      </LabelValueDisplayer>
                    )}
                    <LabelValueDisplayer
                      label='Tramitador'
                      hide={!props.case.caseRequest.scrapedProcessorName}
                    >
                      <Text>{props.case.caseRequest.scrapedProcessorName}</Text>
                    </LabelValueDisplayer>
                    <LabelValueDisplayer label='Ramo'>
                      <Text>{props.case.caseRequest?.ramo?.name}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer label='Agencia'>
                      <Text>{props.case.caseRequest?.agency?.name}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Razón social'
                      hide={!props.case.insuredCompany?.societyReason}
                    >
                      <Text>{props.case.insuredCompany?.societyReason}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Lugar Denuncia'
                      hide={!props.case.caseRequest.complaintReportingLocation}
                    >
                      <Text>
                        {props.case.caseRequest.complaintReportingLocation}
                      </Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Fecha Denuncia'
                      hide={!props.case.caseRequest.denouncedAt}
                    >
                      <Text>
                        {formatDate(props.case.caseRequest.denouncedAt)}
                      </Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Tramitador Email'
                      hide={!props.case.caseRequest.scrapedProcessorEmail}
                    >
                      <Text>
                        {props.case.caseRequest.scrapedProcessorEmail}
                      </Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Tramitador celular'
                      hide={!props.case.caseRequest.scrapedProcessorCellphone}
                    >
                      <Text>
                        {props.case.caseRequest.scrapedProcessorCellphone}
                      </Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Usuario alta'
                      hide={!props.case.caseRequest.complaintRecorderName}
                    >
                      <Text>
                        {props.case.caseRequest.complaintRecorderName}
                      </Text>
                    </LabelValueDisplayer>
                  </CollapsibleContent>

                  <CollapsibleContent title='Asegurado'>
                    <LabelValueDisplayer
                      label='Asegurado'
                      hide={!props.case.customer}
                    >
                      <Text>{buildUserName(props.case.customer)}</Text>
                    </LabelValueDisplayer>
                    <LabelValueDisplayer
                      label='Tipo'
                      hide={!props.case.customer.type}
                    >
                      <Text>{props.case.customer.type}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Celular'
                      hide={!props.case.customer.cellphone}
                    >
                      <Text>{props.case.customer.cellphone}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Documento'
                      hide={!props.case.customer.identification}
                    >
                      <Text>{props.case.customer.identification}</Text>
                    </LabelValueDisplayer>
                    <LabelValueDisplayer
                      label='Nacionalidad'
                      hide={!props.case.customer.countryISO}
                    >
                      <Text>{props.case.customer.countryISO}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Teléfono'
                      hide={!props.case.customer.landlinePhone}
                    >
                      <Text>{props.case.customer.landlinePhone}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='CUIT'
                      hide={!props.case.insuredCompany?.cuit}
                    >
                      <Text>{props.case.insuredCompany?.cuit}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Matricula'
                      hide={!props.case.customer.matricula}
                    >
                      <Text>{props.case.customer.matricula}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Domicilio Real'
                      hide={!props.case.customer.address}
                    >
                      <Text>{props.case.customer.address}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='CUIL'
                      hide={!props.case.customer.cuil}
                    >
                      <Text>{props.case.customer.cuil}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Fecha de nacimiento'
                      hide={!props.case.customer.birthdate}
                    >
                      <Text>{props.case.customer.birthdate}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Lugar de nacimiento'
                      hide={!props.case.customer.birthplace}
                    >
                      <Text>{props.case.customer.birthplace}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Email'
                      hide={!props.case.customer.email}
                    >
                      <Text>{props.case.customer.email}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Póliza'
                      hide={!props.case.caseRequest.policyId}
                    >
                      <Text>{parsePolicy(props.case)}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Patente'
                      hide={!props.case.caseRequest.vehicleLicensePlate}
                    >
                      <Text>{props.case.caseRequest.vehicleLicensePlate}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Año'
                      hide={!props.case.caseRequest.vehicleYear}
                    >
                      <Text>{props.case.caseRequest.vehicleYear}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Uso'
                      hide={!props.case.caseRequest.vehicleUse}
                    >
                      <Text>{props.case.caseRequest.vehicleUse}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Vehículo'
                      hide={!props.case.caseRequest.vehicleModel}
                    >
                      <Text>{props.case.caseRequest.vehicleModel}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer
                      label='Plan'
                      hide={!props.case.caseRequest.policyPlan}
                    >
                      <Text>{props.case.caseRequest.policyPlan}</Text>
                    </LabelValueDisplayer>

                    <LabelValueDisplayer label='Asegurado'>
                      <CaseCustomer
                        readonly={!canPerformActions}
                        caseExtended={props.case}
                        boxStyles={{ ml: !props.isPreview ? 16 : 4 }}
                      />
                    </LabelValueDisplayer>
                  </CollapsibleContent>

                  {props.case.insuranceAgent && (
                    <CollapsibleContent withCollapse={false} title='Productor'>
                      <LabelValueDisplayer label='Nombre'>
                        <Text>{props.case.insuranceAgent.name}</Text>
                      </LabelValueDisplayer>

                      <LabelValueDisplayer label='Correo'>
                        <Text>{props.case.insuranceAgent.email}</Text>
                      </LabelValueDisplayer>

                      <LabelValueDisplayer label='Teléfonos'>
                        <Text>
                          {props.case.insuranceAgent.phoneNumbers.join(', ')}
                        </Text>
                      </LabelValueDisplayer>
                    </CollapsibleContent>
                  )}
                </SimpleGrid>
              </Box>
            </Box>
            {/* @ts-ignore */}
            {props.case.caseRequest?.annotation && (
              <>
                <Divider my={2} />
                <CaseAnnotations
                  withoutCreate
                  // @ts-ignore
                  annotation={props.case.caseRequest?.annotation}
                />
              </>
            )}
            {props.renderPrevCollapse}
            <Divider my={2} />
            <CaseArtifacts
              isPreview={props.isPreview}
              artifacts={props.case.artifacts}
              caseId={caseId}
              withoutCreate={!hasRelationWithCase}
            />
            {!!props.case.news && (
              <>
                <Divider my={2} />
                <CaseNews
                  withReply
                  withoutCreate={!hasRelationWithCase}
                  caseId={props.id}
                  caseExtended={props.case}
                />
              </>
            )}
            <Divider my={2} />
            <CaseThreads
              withoutCreate={!canPerformActions}
              caseId={props.id}
              threads={props.case.threads}
              Navigator={props.Navigator}
            />
            <Divider my={2} />
            <CaseMails
              isCaseClosed={props.case.status === 'closed'}
              query={props.query}
              caseId={props.id}
              mails={props.case.mails}
            />
            {auth.company.type === 'insurer' && (
              <>
                <Divider my={2} />
                <CaseInvestigations
                  withoutCreate={!canPerformActions}
                  investigations={props.case.investigations}
                  caseId={caseId}
                  query={props.query}
                />
              </>
            )}

            <Divider my={2} />
            {auth.company.type === 'insurer' && (
              <>
                <CaseSavings
                  withoutCreate={!canUpdateSaving}
                  formulas={props.case.formulas}
                  case={props.case}
                  query={props.query}
                />
                <Divider my={2} />

                <CaseDetailsSsn
                  companyEnums={props?.enums}
                  withoutCreate={!canPerformActions}
                  case={props.case}
                  query={props.query}
                />
                <Divider my={2} />
              </>
            )}
            <CaseCustomerReports
              integrationConnections={props.integrationConnections}
              withoutCreate={!hasRelationWithCase}
              caseId={caseId}
              customer={props.case.customer}
              customerReports={props.case.customerReports}
            />
            <Divider my={2} />
            <CaseInspections
              inspections={props.case.inspections}
              withoutCreate={!canPerformActions}
              caseId={caseId}
              resource={inspectionResource}
            />
            {props.SectionsExtended}
            <Divider my={2} />
            <CaseSinisterDocuments case={props.case} />
            <Divider my={2} />
            <CaseClaims case={props.case} />
            <Divider my={2} />
            <CaseReportInspections case={props.case} />
            <Divider my={2} />
            <CaseExpertExaminations case={props.case} />
          </Box>

          <Box
            zIndex={
              isExternalMenuOpen || isExternalDialogOpen ? '-10' : 'unset'
            }
            id='detail'
            w={props.column ? ['full', null, null, null, '35%', '30%'] : 'full'}
            position='relative'
          >
            {auth.company.type === 'insurer' && (
              <CaseTimeline
                case={props.case}
                events={props.case.events}
                auth={auth}
              />
            )}
            {isCurrentUserIncluded && canPerformActions && (
              <Box
                w='full'
                left='0'
                zIndex={isExternalDialogOpen ? '-10' : 'unset'}
                position={
                  isExternalDialogOpen
                    ? 'unset'
                    : props.withStickyPos
                    ? 'sticky'
                    : 'fixed'
                }
                bottom='-10px'
              >
                <CommentInputCase caseId={props.id} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </CaseDetailProvider>
  )
}
