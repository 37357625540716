import {
  Box,
  chakra,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Spinner,
  Stack,
  VStack,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { PriceGroupClient } from 'kach-clients'
import { buildTestId, translator } from 'kach-commons'
import { Select as ChakraReactSelect } from 'chakra-react-select'
import React from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { AiFillPhone, AiOutlinePlus } from 'react-icons/ai'
import { BsFillPersonFill } from 'react-icons/bs'
import { CgOrganisation } from 'react-icons/cg'
import { IoIosRemove } from 'react-icons/io'
import { countriesAndStateSlim } from '../constants/countries-states.slim'
import { PRICE_GROUPS_QUERY_KEY } from '../constants/query-keys'
import { useCompanyEnums } from '../hooks/useCompanyEnums'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { ContactSchema, IContactSchema } from '../schemas/contact.schema'
import { investigationTypes } from '../schemas/create-contact-price.schema'
export const ContactLabForm = (props: {
  formId?: string
  onSubmit: (data: IContactSchema) => void
  defaultValues?: Partial<IContactSchema>
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
    setValue,
  } = useForm<IContactSchema>({
    resolver: zodResolver(ContactSchema),
    defaultValues: {
      ...props.defaultValues,
      cellphones: props.defaultValues.cellphones || [
        {
          cellphone: '',
          reference: '',
        },
      ],
      responsible: {
        documentType: 'dni',
        ...props.defaultValues?.responsible,
      },
    },
  })

  const fields = watch()

  const { isLoading, data } = useQuerySingleton(
    [PRICE_GROUPS_QUERY_KEY],
    () => PriceGroupClient.fetch(),
    {
      retry: 1,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  const { append, remove } = useFieldArray({
    name: 'cellphones',
    control,
  })

  const { append: appendSinisterTypes, remove: removeSinisterTypes } =
    useFieldArray({
      name: 'sinisterTypesSupported',
      control,
    })

  const states = countriesAndStateSlim.find(
    (country) => country.iso2 === fields.country,
  )?.states

  const companyEnums = useCompanyEnums()

  const options: any[] = investigationTypes.map((investigationType) => {
    return {
      label: translator(investigationType),
      value: investigationType,
    }
  })

  const areAllChecked = companyEnums.data.sinisterTypes.every((sinisterType) =>
    (fields.sinisterTypesSupported || []).some(
      (sinisterTypeCandidate) => sinisterTypeCandidate.id === sinisterType.id,
    ),
  )

  const isIndeterminate =
    !areAllChecked && (fields.sinisterTypesSupported || []).length > 0

  const defaultPriceGroup = data?.priceGroups.find(
    (price) => price.id === props.defaultValues?.priceGroupId,
  )

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Box {...buildTestId('create-contact-lab-body')}>
      <chakra.form
        id={props.formId}
        onSubmit={handleSubmit(props.onSubmit)}
        noValidate
      >
        <HStack
          spacing={'10'}
          display={'flex'}
          flexDir={'row'}
          alignItems={'flex-start'}
        >
          <Box width={'45%'}>
            <CgOrganisation fontSize={'1.5rem'} />
            <Heading fontSize={'2xl'} mb={'4'}>
              Estudio de investigación
            </Heading>
            <VStack spacing={'4'}>
              <FormControl isRequired isInvalid={!!errors.name}>
                <FormLabel>Razón social</FormLabel>
                <Input {...register('name')} />
                {errors.name && (
                  <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isRequired isInvalid={!!errors.email}>
                <FormLabel>Email</FormLabel>
                <Input {...register('email')} type='email' />
                {errors.email && (
                  <FormErrorMessage>{errors.email.message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isRequired isInvalid={!!errors.priceGroupId}>
                <FormLabel>Franja de honorarios</FormLabel>
                <Select {...register('priceGroupId')}>
                  <option value='' selected disabled>
                    Selecciona una opción
                  </option>
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    data.priceGroups.map((ele, index) => {
                      const selected = defaultPriceGroup?.id === ele.id
                      return (
                        <option value={ele.id} key={index} selected={selected}>
                          {ele.name}
                        </option>
                      )
                    })
                  )}
                </Select>
                {errors.priceGroupId && (
                  <FormErrorMessage>
                    {errors.priceGroupId.message}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.investigationTypesSupported}>
                <FormLabel>Tipo de investigaciones</FormLabel>
                <ChakraReactSelect
                  placeholder='Selecciona una opción'
                  isMulti
                  defaultValue={(fields.investigationTypesSupported || []).map(
                    (investigationType) => {
                      return {
                        label: translator(investigationType),
                        value: investigationType,
                      }
                    },
                  )}
                  onChange={(selected) =>
                    setValue(
                      'investigationTypesSupported',
                      selected.map(
                        (ele) => ele.value,
                      ) as IContactSchema['investigationTypesSupported'],
                    )
                  }
                  options={options}
                />
                {errors.investigationTypesSupported && (
                  <FormErrorMessage>
                    {errors.investigationTypesSupported.message}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.sinisterTypesSupported}>
                <FormLabel>Tipo de siniestros</FormLabel>
                {companyEnums.isLoading || !companyEnums.data ? (
                  <Spinner />
                ) : (
                  <>
                    <Checkbox
                      isChecked={areAllChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValue(
                            'sinisterTypesSupported',
                            companyEnums.data.sinisterTypes.map(
                              (sinisterType) => ({ id: sinisterType.id }),
                            ),
                          )
                        }

                        if (!e.target.checked) {
                          setValue('sinisterTypesSupported', [])
                        }
                      }}
                    >
                      {(() => {
                        if (areAllChecked) {
                          return 'Deseleccionar todos'
                        }

                        return 'Seleccionar todos'
                      })()}
                    </Checkbox>
                    <Stack pl={6} mt={1} spacing={1}>
                      {companyEnums.data.sinisterTypes.map(
                        (sinisterType, index) => {
                          const isChecked = (
                            fields.sinisterTypesSupported || []
                          ).some(
                            (sinisterTypeCandidate) =>
                              sinisterTypeCandidate.id === sinisterType.id,
                          )

                          return (
                            <Checkbox
                              key={index}
                              isChecked={isChecked}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  appendSinisterTypes({
                                    id: sinisterType.id,
                                  })
                                }

                                const indexToRemove = (
                                  fields.sinisterTypesSupported || []
                                ).findIndex(
                                  (sinisterTypeCandidate) =>
                                    sinisterTypeCandidate.id ===
                                    sinisterType.id,
                                )

                                if (!e.target.checked) {
                                  removeSinisterTypes(indexToRemove)
                                }
                              }}
                            >
                              {sinisterType.name}
                            </Checkbox>
                          )
                        },
                      )}
                    </Stack>
                  </>
                )}

                {errors.sinisterTypesSupported && (
                  <FormErrorMessage>
                    {errors.sinisterTypesSupported.message}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.alias}>
                <FormLabel>Nombre fantasia</FormLabel>
                <Input {...register('alias')} />
                {errors.alias && (
                  <FormErrorMessage>{errors.alias.message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.country}>
                <FormLabel>País</FormLabel>
                <Select {...register('country')}>
                  <option value='' selected disabled>
                    Selecciona una opción
                  </option>

                  {countriesAndStateSlim.map((country, index) => {
                    return (
                      <option key={index} value={country.iso2}>
                        {country.emoji} {country.name}
                      </option>
                    )
                  })}
                </Select>

                {errors.country && (
                  <FormErrorMessage>{errors.country.message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.state}>
                <FormLabel>Estado</FormLabel>
                <Select {...register('state')}>
                  <option value='' selected disabled>
                    Selecciona una opción
                  </option>

                  {(states || []).map((state, index) => {
                    return (
                      <option key={index} value={state.state_code}>
                        {state.name}
                      </option>
                    )
                  })}
                </Select>

                {errors.state && (
                  <FormErrorMessage>{errors.state.message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.city}>
                <FormLabel>Ciudad</FormLabel>
                <Input {...register('city')} />
                {errors.city && (
                  <FormErrorMessage>{errors.city.message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.address}>
                <FormLabel>Dirección</FormLabel>
                <Input {...register('address')} />
                {errors.address && (
                  <FormErrorMessage>{errors.address.message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.providerNumber}>
                <FormLabel>Número de proveedor</FormLabel>
                <Input {...register('providerNumber')} />
                {errors.providerNumber && (
                  <FormErrorMessage>
                    {errors.providerNumber.message}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.cellphones}>
                <FormLabel mb='0'>Celulares</FormLabel>
                <Grid
                  alignItems={'flex-end'}
                  templateColumns='repeat(3, 1fr)'
                  gap={6}
                >
                  {fields.cellphones.map((cellphone, index) => {
                    return (
                      <>
                        <GridItem key={index} w='100%'>
                          <FormControl>
                            <InputGroup w='full'>
                              <InputLeftAddon children={<AiFillPhone />} />
                              <Input
                                {...register(`cellphones.${index}.cellphone`)}
                              />
                            </InputGroup>
                          </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                          <FormControl>
                            <FormLabel>Referencia</FormLabel>
                            <Input
                              {...register(`cellphones.${index}.reference`)}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                          {index === 0 ? (
                            <IconButton
                              onClick={() =>
                                append({ reference: '', cellphone: '' })
                              }
                              aria-label='Agregar'
                              icon={<AiOutlinePlus />}
                            />
                          ) : (
                            <IconButton
                              onClick={() => remove(index)}
                              aria-label='Remover'
                              icon={<IoIosRemove />}
                            />
                          )}
                        </GridItem>
                      </>
                    )
                  })}
                </Grid>
              </FormControl>
            </VStack>
          </Box>

          <Box width={'50%'}>
            <BsFillPersonFill fontSize={'1.5rem'} />
            <Heading fontSize={'2xl'} mb={'4'}>
              Responsable
            </Heading>

            <VStack spacing={'4'}>
              <FormControl isInvalid={!!errors.responsible?.firstName}>
                <FormLabel>Nombre</FormLabel>
                <Input {...register('responsible.firstName')} />

                {errors.responsible?.firstName?.message && (
                  <FormErrorMessage>
                    {errors.responsible.firstName.message}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.responsible?.lastName}>
                <FormLabel>Apellido</FormLabel>
                <Input {...register('responsible.lastName')} />

                {errors.responsible?.lastName?.message && (
                  <FormErrorMessage>
                    {errors.responsible?.lastName?.message}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.responsible?.identification}>
                <FormLabel>CUIT / CUIL</FormLabel>
                <Input
                  {...register('responsible.identification')}
                  type='number'
                />
                {errors.responsible?.identification && (
                  <FormErrorMessage>
                    {errors.responsible.identification.message}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                isRequired
                isInvalid={!!errors.responsible?.documentType}
              >
                <FormLabel>Tipo Documento</FormLabel>
                <Select {...register('responsible.documentType')}>
                  {
                    <option key={'dni'} value={'dni'}>
                      DNI
                    </option>
                  }
                </Select>
                {errors.responsible?.documentType && (
                  <FormErrorMessage>
                    {errors.responsible.documentType.message}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.responsible?.documentNumber}>
                <FormLabel>Número Documento</FormLabel>
                <Input
                  {...register('responsible.documentNumber')}
                  type='number'
                />
                {errors.responsible?.documentNumber && (
                  <FormErrorMessage>
                    {errors.responsible.documentNumber.message}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.responsible?.enrollment}>
                <FormLabel>Número Matricula</FormLabel>
                <Input {...register('responsible.enrollment')} />
                {errors.responsible?.enrollment && (
                  <FormErrorMessage>
                    {errors.responsible.enrollment.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </VStack>
          </Box>
        </HStack>
      </chakra.form>
    </Box>
  )
}
