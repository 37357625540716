import {
  Alert,
  AlertIcon,
  Box,
  chakra,
  Link as ChakraLink,
  Stack,
} from '@chakra-ui/react'
import { ICaseExtended } from 'kach-clients'
import { translator } from 'kach-commons'
import React, { useMemo } from 'react'
import { buildUserName } from '../utils/build-user-name'

export const CaseSuspectsAlert = ({ data }: { data: ICaseExtended }) => {
  const policyholderSuspects = useMemo(
    () =>
      data.suspects?.filter(
        (suspect) => suspect.documentNumber === data.customer.identification,
      ),
    [data.suspects],
  )

  if (!policyholderSuspects?.length) return null

  const hasSomeCase = policyholderSuspects.some(
    (suspect) =>
      !!suspect.case ||
      (suspect.caseBindingType === 'literal' && suspect.caseLiteral),
  )

  return (
    <Alert fontSize='sm' status='warning'>
      <Stack direction={'row'}>
        <AlertIcon />
        <Box>
          El socio{' '}
          <strong>
            {data.customer.firstName || data.customer.lastName
              ? `${buildUserName(data.customer)} - `
              : ''}
            {data.customer.identification}
          </strong>{' '}
          {(() => {
            if (!hasSomeCase)
              return 'se encuentra en la lista de antecedentes pero no se encuentra vinculado a ningún caso.'

            return (
              <>
                posee antecedentes por la vinculación con los siniestros:{' '}
                {policyholderSuspects.map((suspect, index) => {
                  if (
                    !suspect.case &&
                    suspect.caseBindingType === 'literal' &&
                    suspect.caseLiteral
                  )
                    return (
                      <chakra.span key={suspect.id}>
                        {' '}
                        {suspect?.caseLiteral} ({translator(suspect.type)})
                        {index !== policyholderSuspects.length - 1 ? ', ' : ''}
                      </chakra.span>
                    )

                  return (
                    <ChakraLink
                      key={suspect.id}
                      textDecor='underline'
                      href={`/case/request/${suspect?.case?.caseRequest?.id}`}
                      target='_blank'
                    >
                      {suspect?.case?.caseRequest?.sinisterId} (
                      {translator(suspect.type)})
                      {index !== policyholderSuspects.length - 1 ? ', ' : ''}
                    </ChakraLink>
                  )
                })}
              </>
            )
          })()}
        </Box>
      </Stack>
    </Alert>
  )
}
