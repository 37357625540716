import { IVirtualViewFilter } from 'kach-clients'
import { IDynamicObject } from '../components/InvestigationLine'
import { ICandidateFilter, IFilter } from '../components/TableAbstract'
import { IVirtualViewRefFilter } from '../providers/TableFiltersProvider'

export const params2VirtualView = (
  params: IDynamicObject,
): IVirtualViewFilter[] => {
  const { search, page, ...filters } = params

  return Object.entries(filters).reduce<IVirtualViewFilter[]>(
    (acc, [column, values]) => {
      const type =
        Array.isArray(values) &&
        values.every((value) => typeof value?.id === 'number')
          ? 'references'
          : 'values'

      console.log('type', type)
      console.log('values', values)

      const hasDateFilterShape =
        typeof values === 'object'
          ? (values as Object).hasOwnProperty('from') &&
            (values as Object).hasOwnProperty('to')
          : false

      const filter: IVirtualViewFilter = {
        type,
        ...(type === 'references'
          ? {
              column: 'name',
              entityName: column,
              references: values.map(
                (value: { id: number }): IVirtualViewRefFilter => {
                  return {
                    entityId: value.id,
                  }
                },
              ),
            }
          : {
              column,
              values: Array.isArray(values)
                ? values.map((val: { name: string } | string) => {
                    return {
                      type: typeof val === 'number' ? 'number' : 'literal',
                      literal:
                        typeof val === 'string' || typeof val === 'number'
                          ? val
                          : val.name,
                    }
                  })
                : [
                    !hasDateFilterShape
                      ? {
                          type: 'literal',
                          literal: values,
                        }
                      : {
                          type: 'date',
                          date: values,
                        },
                  ],
            }),
      }

      return [...acc, filter]
    },
    [] as IVirtualViewFilter[],
  )
}

export const virtualView2filters = (
  candidateFilters: ICandidateFilter[],
  virtualViewFilters: IVirtualViewFilter[],
): IFilter[] => {
  return virtualViewFilters.map((filter: IVirtualViewFilter): IFilter => {
    const isReference = filter.type === 'references'

    const candidateFilterMatch = candidateFilters.find((c) => {
      if (!isReference) {
        return c.column === filter.column
      }

      return c.column === filter.entityName
    })

    //This is for hidden filters like sort columns
    if (!candidateFilterMatch) {
      const [mainValue] = filter.values

      const type = ['ASC', 'DESC'].includes(mainValue.literal) ? 'sort' : 'text'

      return {
        ...(mainValue.date || {}),
        label: filter.column,
        column: filter.column,
        value: mainValue.literal,
        disabledRender: ['sort'].includes(type),
        type,
      }
    }

    switch (candidateFilterMatch.type) {
      case 'enum':
        return {
          ...candidateFilterMatch,
          enums: (candidateFilterMatch?.enumData || []).map((enumOpt) => {
            if (isReference) {
              const referenceMatched = filter.references.some(
                (ref) => ref.entityId === enumOpt.value,
              )

              return {
                checked: referenceMatched,
                name: enumOpt.name,
              }
            }

            if (!isReference) {
              const literalMatched = filter.values.some(
                (val) => val.literal === enumOpt.value,
              )

              return {
                checked: literalMatched,
                name: enumOpt.name,
              }
            }
          }),
        }

      case 'date':
        const [date] = filter.values

        return {
          ...candidateFilterMatch,
          ...date.date,
        }

      case 'range-slider': {
        const [from, to] = filter.values!

        return {
          ...candidateFilterMatch,
          value: [+from.literal, +to.literal],
        }
      }

      default:
        return candidateFilterMatch
    }
  })
}
