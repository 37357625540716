var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  DEFAULT_COUNTRY_ISO: () => DEFAULT_COUNTRY_ISO,
  DEFAULT_TIMEZONE: () => DEFAULT_TIMEZONE,
  DEV_ENV_TYPE: () => DEV_ENV_TYPE,
  MAX_WEBHOOKS_PER_COMPANY: () => MAX_WEBHOOKS_PER_COMPANY,
  actions: () => actions,
  activeInvestigationStatus: () => activeInvestigationStatus,
  alertTables: () => alertTables,
  caseEndorsementFilterOpts: () => caseEndorsementFilterOpts,
  casePriorityFilterOpts: () => casePriorityFilterOpts,
  caseReminderTypes: () => caseReminderTypes,
  caseRequestResourceColumns: () => caseRequestResourceColumns,
  caseResourceColumns: () => caseResourceColumns,
  caseResults: () => caseResults,
  caseTypes: () => caseTypes,
  caseVisibility: () => caseVisibility,
  columnNames: () => columnNames,
  companyEnumExtraValue: () => companyEnumExtraValue,
  companyRetentionMotiveTypes: () => companyRetentionMotiveTypes,
  companyTypes: () => companyTypes,
  conditionOperators: () => conditionOperators,
  conditionTypes: () => conditionTypes,
  documentType: () => documentType,
  getAllColumnPaths: () => getAllColumnPaths,
  insurerConditions: () => insurerConditions,
  intervalType: () => intervalType,
  investigationResourceColumns: () => investigationResourceColumns,
  investigationStatus: () => investigationStatus,
  investigationTypes: () => investigationTypes,
  labConditions: () => labConditions,
  limitColumnNames: () => limitColumnNames,
  limitReferenceTables: () => limitReferenceTables,
  limitTypes: () => limitTypes,
  priceValidityTypes: () => priceValidityTypes,
  referenceTables: () => referenceTables,
  repositoryTreeNodeTypes: () => repositoryTreeNodeTypes,
  resources: () => resources,
  retentionRequestResourceColumns: () => retentionRequestResourceColumns,
  retentionRequestStatus: () => retentionRequestStatus,
  retrieveCaseInspectionTokenResource: () => retrieveCaseInspectionTokenResource,
  ssnPersonInvolvedGroup: () => ssnPersonInvolvedGroup,
  ssnPersonInvolvedType: () => ssnPersonInvolvedType,
  ssnStatementCsvOutputKeys: () => ssnStatementCsvOutputKeys,
  stopCaseRequestResourceColumns: () => stopCaseRequestResourceColumns,
  stopCaseRequestStatus: () => stopCaseRequestStatus,
  subscribableEvents: () => subscribableEvents,
  suspectBatchEquivalenceTypes: () => suspectBatchEquivalenceTypes,
  suspectCaseBindingTypes: () => suspectCaseBindingTypes,
  suspectEntitySources: () => suspectEntitySources,
  suspectsResourceColumns: () => suspectsResourceColumns,
  tableColumns: () => tableColumns,
  transformationApplyTo: () => transformationApplyTo,
  transformers: () => transformers,
  vehicleTypes: () => vehicleTypes,
  virtualViewResources: () => virtualViewResources,
  virtualViewScopes: () => virtualViewScopes
});
module.exports = __toCommonJS(src_exports);

// src/constants/alert.ts
var transformers = ["date_diff_workdays", "date_diff"];
var transformationApplyTo = ["comparison", "entity"];
var alertTables = [
  {
    label: "Casos",
    table: "case",
    type: "table",
    columns: [
      {
        label: "Estado",
        column: "status",
        type: "enum",
        enum: [
          {
            value: "not_assigned",
            label: "Sin asignaci\xF3n"
          },
          {
            value: "in_progress",
            label: "En progreso"
          },
          {
            value: "closed",
            label: "Cerrado"
          }
        ]
      },
      {
        label: "Solicitud de caso",
        column: "caseRequest",
        type: "table",
        columns: [
          {
            column: "denouncedAt",
            type: "date",
            label: "Fecha de denuncia"
          }
        ]
      }
    ]
  },
  {
    type: "table",
    label: "Intentos de env\xEDo de carta documento",
    table: "mailAttempt",
    columns: [
      {
        type: "date",
        column: "receivedAt",
        label: "Recibido el"
      },
      {
        type: "date",
        column: "sentAt",
        label: "Enviado el"
      },
      {
        type: "date",
        column: "expiredAt",
        label: "Expirado el"
      },
      {
        label: "Estado del caso",
        column: "mail.case.status",
        type: "enum",
        enum: [
          {
            value: "not_assigned",
            label: "Sin asignaci\xF3n"
          },
          {
            value: "in_progress",
            label: "En progreso"
          },
          {
            value: "closed",
            label: "Cerrado"
          }
        ]
      }
    ]
  },
  {
    type: "table",
    label: "Investigaciones",
    table: "investigation",
    columns: [
      {
        label: "Estado del caso",
        column: "case.status",
        type: "enum",
        enum: [
          {
            value: "not_assigned",
            label: "Sin asignaci\xF3n"
          },
          {
            value: "in_progress",
            label: "En progreso"
          },
          {
            value: "closed",
            label: "Cerrado"
          }
        ]
      },
      {
        type: "enum",
        column: "status",
        label: "Estado",
        enum: [
          {
            label: "Pendiente",
            value: "pending"
          },
          {
            label: "Aceptado",
            value: "accepted"
          },
          {
            label: "Rechazado",
            value: "rejected"
          },
          {
            label: "Cerrado",
            value: "closed"
          }
        ]
      },
      {
        type: "date",
        column: "closedAt",
        label: "Cerrado el"
      },
      {
        type: "date",
        column: "acceptedAt",
        label: "Aceptado el"
      },
      {
        type: "date",
        column: "createdAt",
        label: "Creado el"
      }
    ]
  }
];
function getAllColumnPaths(entity) {
  const table = alertTables.find((ele) => ele.table === entity);
  if (!table) {
    return [];
  }
  const paths = [];
  for (const column of table.columns) {
    const isTable = column.type === "table";
    if (isTable) {
      for (const nestedColumn of column.columns) {
        paths.push(`${column.column}.${nestedColumn.column}`);
      }
    }
    if (!isTable) {
      paths.push(column.column);
    }
  }
  return paths;
}

// src/constants/virtual-view.ts
var virtualViewScopes = ["company", "user"];
var virtualViewResources = [
  "case",
  "case_request",
  "investigation",
  "stop_case_request",
  "suspect",
  "retention_request",
  "statement"
];
var caseResourceColumns = [
  "status",
  "sinisterId",
  "deadline",
  "sinisterType",
  "createdAt",
  "calculated_created_at",
  "reason",
  "responsible",
  "lastNewsDate",
  "lastInvestigationDate",
  "lastInvestigationNewsDate",
  "internal_status",
  "last_lab_report_date",
  "caseType",
  "appliesEndorsement"
];
var caseRequestResourceColumns = [
  "status",
  "sinisterId",
  "createdAt",
  "sinisterType",
  "reason",
  "denouncedAt",
  "processor",
  "responsible"
];
var investigationResourceColumns = [
  "companyName",
  "sinisterId",
  "investigation_status",
  "delay",
  "case_status",
  "derived_at",
  "rejected_at",
  "accepted_at"
];
var stopCaseRequestResourceColumns = [
  "sinisterId",
  "createdAt",
  "status",
  "createdBy",
  "handler"
];
var retentionRequestResourceColumns = [
  "sinisterId",
  "createdAt",
  "status",
  "createdBy",
  "handler",
  "policyholder",
  "insuranceAgent"
];
var suspectsResourceColumns = [
  "sinisterId",
  "person",
  "role",
  "result"
];
var tableColumns = {
  case: caseResourceColumns,
  case_request: caseRequestResourceColumns,
  investigation: investigationResourceColumns,
  stop_case_request: stopCaseRequestResourceColumns,
  suspect: suspectsResourceColumns,
  retention_request: retentionRequestResourceColumns,
  statement: caseResourceColumns
};

// src/constants/ssn.ts
var ssnStatementCsvOutputKeys = [
  "emp",
  "id",
  "hasInvestigation",
  "derivedAt",
  "sinisterId",
  "ramo",
  "investigationSummary",
  "result",
  "resultDescription",
  "closeMotive",
  "allegedInvolvedType",
  "allegedInvolvedName",
  "allegedInvolvedDocumentType",
  "allegedInvolvedDocumentNumber",
  "allegedInvolvedEin",
  "allegedInvolvedAddress",
  "allegedInvolvedState",
  "allegedInvolvedCity",
  "allegedInvolvedZipCode",
  "professionalProfession",
  "professionalName",
  "professionalDocumentType",
  "professionalDocumentNumber",
  "professionalEin",
  "professionalAddress",
  "professionalState",
  "professionalCity",
  "professionalZipCode",
  "witnessName",
  "witnessDocumentType",
  "witnessDocumentNumber",
  "witnessEin",
  "witnessAddress",
  "witnessState",
  "witnessCity",
  "witnessZipCode",
  "lawyerType",
  "lawyerName",
  "lawyerDocumentType",
  "lawyerDocumentNumber",
  "lawyerEin",
  "lawyerState",
  "lawyerCity",
  "lawyerZipCode",
  "lawyerEnrollment",
  "liquidatorName",
  "liquidatorDocumentType",
  "liquidatorDocumentNumber",
  "liquidatorEin",
  "liquidatorSummary",
  "liquidatorEnrollment",
  "agreement",
  "surrender",
  "reluctance",
  "rejected",
  "prescription",
  "expiration",
  "demandRejected",
  "juice",
  "criminalComplaint",
  "complaint",
  "probation",
  "criminalConviction",
  "suspension",
  "others",
  "saving",
  "criminalComplaintPerform",
  "criminalComplaintAuthority",
  "criminalComplaintFollowUp",
  "criminalComplaintProfessionalAdvice",
  "criminalComplaintProfessionalAdviceName",
  "observations"
];
var ssnPersonInvolvedType = [
  "insured",
  "pas",
  "third",
  "driver",
  "witness",
  "lawyer",
  "professional",
  "liquidator"
];
var ssnPersonInvolvedGroup = [
  "insuredCustomer",
  "lawyer",
  "witness",
  "professional"
];

// src/constants/document-type.ts
var documentType = ["dni"];

// src/constants/case-inspection-query.ts
var retrieveCaseInspectionTokenResource = [
  "case",
  "case_request",
  "investigation"
];

// src/constants/env.ts
var DEV_ENV_TYPE = ["prod", "dev"];

// src/constants/price.ts
var priceValidityTypes = [
  "active",
  "expired",
  "future",
  "all"
];

// src/constants/webhook.ts
var MAX_WEBHOOKS_PER_COMPANY = 5;
var subscribableEvents = [
  "case_responsibility_delegated",
  "case_news_created",
  "case_updated",
  "case_closed",
  "case_reopened",
  "investigation_report_accepted",
  "case_taken",
  "case_assigned",
  "case_request_updated"
];

// src/constants/repository-file.ts
var repositoryTreeNodeTypes = ["folder", "file"];

// src/constants/case-investigation.ts
var investigationStatus = [
  "pending",
  "accepted",
  "rejected",
  "closed",
  "canceled"
];
var activeInvestigationStatus = [
  "accepted",
  "pending"
];
var investigationTypes = ["f2f", "virtual"];

// src/constants/stop-case-request.ts
var stopCaseRequestStatus = ["open", "approved", "rejected"];

// src/constants/company-enum.ts
var companyEnumExtraValue = ["ssn-results.is_other"];
var companyTypes = ["lab", "insurer", "system"];
var companyRetentionMotiveTypes = ["other", "reference"];
var DEFAULT_TIMEZONE = "America/Argentina/Buenos_Aires";
var DEFAULT_COUNTRY_ISO = "AR";

// src/constants/permission.ts
var resources = [
  "limit",
  "case",
  "case_enums",
  "invitation",
  "company",
  "case_request",
  "case_request_news",
  "case_request_processor",
  "user",
  "investigation",
  "target_group",
  "case_load_balance_rule",
  "contact",
  "price",
  "price_group",
  "contact_price",
  "role",
  "alert",
  "stats",
  "integration",
  "virtual_view",
  "workflow",
  "case_draft",
  "statement",
  "company_link",
  "trash_transaction",
  "investigation_user",
  "autoinspector-integration",
  "stop_case_request",
  "comparative_stats",
  "case_request_user",
  "suspect",
  "repository_file",
  "retention_request",
  "calendar_event",
  "case_visibility",
  "hidden_case",
  "bulk_investigation_request_news"
];
var actions = [
  "create",
  "update",
  "read",
  "read_all",
  "read_own",
  "delete",
  "take",
  "define",
  "highlight",
  "assign",
  "create_own",
  "create_all",
  "update_own",
  "update_all",
  "remove_own",
  "remove_all",
  "manage"
];

// src/constants/case.ts
var caseResults = [
  "positive",
  "negative",
  "elements_for_rejection",
  "in_extension",
  "canceled",
  "possible_prescription",
  "without_result",
  "not_derived",
  "canceled_by_management"
];
var casePriorityFilterOpts = [
  "highlighted",
  "not_highlighted"
];
var caseEndorsementFilterOpts = ["apply", "not_apply"];
var caseReminderTypes = ["active", "expired", "none"];
var caseVisibility = ["hidden", "visible"];
var caseTypes = ["insured", "third_party"];
var vehicleTypes = ["car", "moto", "truck", "bus"];

// src/constants/retention-request.ts
var retentionRequestStatus = ["open", "approved", "rejected"];

// src/constants/limit.ts
var intervalType = ["days", "months", "years", "ever"];
var limitReferenceTables = [
  "agency",
  "sinisterType",
  "reason",
  "internal_status",
  "result",
  "investigationType"
];
var limitColumnNames = [
  "agency",
  "sinisterType",
  "reason",
  "internal_status",
  "result",
  "investigationType"
];
var insurerConditions = [
  "reason",
  "sinisterType",
  "agency",
  "internal_status",
  "result"
];
var labConditions = ["investigationType"];
var limitTypes = ["insurer", "lab"];

// src/constants/condition.ts
var referenceTables = [
  "agency",
  "sinisterType",
  "reason",
  "caseType"
];
var columnNames = [
  "agency",
  "sinisterType",
  "reason",
  "caseType"
];
var conditionTypes = ["reference", "literal"];
var conditionOperators = ["equal"];

// src/constants/suspect.ts
var suspectEntitySources = ["person_ssn", "manual"];
var suspectBatchEquivalenceTypes = ["reference", "literal"];
var suspectCaseBindingTypes = ["reference", "literal"];
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  DEFAULT_COUNTRY_ISO,
  DEFAULT_TIMEZONE,
  DEV_ENV_TYPE,
  MAX_WEBHOOKS_PER_COMPANY,
  actions,
  activeInvestigationStatus,
  alertTables,
  caseEndorsementFilterOpts,
  casePriorityFilterOpts,
  caseReminderTypes,
  caseRequestResourceColumns,
  caseResourceColumns,
  caseResults,
  caseTypes,
  caseVisibility,
  columnNames,
  companyEnumExtraValue,
  companyRetentionMotiveTypes,
  companyTypes,
  conditionOperators,
  conditionTypes,
  documentType,
  getAllColumnPaths,
  insurerConditions,
  intervalType,
  investigationResourceColumns,
  investigationStatus,
  investigationTypes,
  labConditions,
  limitColumnNames,
  limitReferenceTables,
  limitTypes,
  priceValidityTypes,
  referenceTables,
  repositoryTreeNodeTypes,
  resources,
  retentionRequestResourceColumns,
  retentionRequestStatus,
  retrieveCaseInspectionTokenResource,
  ssnPersonInvolvedGroup,
  ssnPersonInvolvedType,
  ssnStatementCsvOutputKeys,
  stopCaseRequestResourceColumns,
  stopCaseRequestStatus,
  subscribableEvents,
  suspectBatchEquivalenceTypes,
  suspectCaseBindingTypes,
  suspectEntitySources,
  suspectsResourceColumns,
  tableColumns,
  transformationApplyTo,
  transformers,
  vehicleTypes,
  virtualViewResources,
  virtualViewScopes
});
