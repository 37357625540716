const timeStringToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number)
  return hours * 60 + minutes
}

export const getIsOutOfOfficeHours = (
  date: string,
  workingHoursStart: string | undefined,
  workingHoursEnd: string | undefined,
): boolean => {
  if (!workingHoursStart || !workingHoursEnd) return false

  const nowDate = new Date(date)
  const nowTime = `${nowDate.getUTCHours()}:${nowDate.getUTCMinutes()}`

  const workingHoursStartMinutes = timeStringToMinutes(workingHoursStart)
  const workingHoursEndMinutes = timeStringToMinutes(workingHoursEnd)
  const nowTimeMinutes = timeStringToMinutes(nowTime)

  return (
    nowTimeMinutes < workingHoursStartMinutes ||
    nowTimeMinutes > workingHoursEndMinutes
  )
}
