import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { CaseClient, ICaseExtended, IUser } from 'kach-clients'
import React, { useCallback, useState } from 'react'
import { FaExchangeAlt, FaUsers } from 'react-icons/fa'
import { CASES_QUERY_KEY } from '../constants/query-keys'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { buildUserName } from '../utils/build-user-name'
import { UserDisplayer } from './UserDisplayer'
import { UsersSelectSearchable } from './UsersSelectSearchable'

export const ProcessorDisplayer = (props: { case: ICaseExtended }) => {
  const { isOpen: isOpenPopover, onToggle } = useDisclosure()
  const auth = useAuth()

  const canUpdate = auth.role.permissions.find(
    (p) => p.action === 'define' && p.resource === 'case_request_processor',
  )

  const [isOpen, setOpen] = useState(false)

  const [targetProcessor, setTargetProcessor] = useState<IUser>()

  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const onAssignProcessor = useCallback(
    (onClose: () => void) => {
      return () => {
        setIsLoading(true)

        toast.promise(
          CaseClient.updateProcessor(props.case.id, targetProcessor.email),
          {
            loading: {
              title: 'Asignando procesador...',
            },
            success: () => {
              const userName = buildUserName(targetProcessor)
              queryClient.invalidateQueries([buildCaseQueryKey(props.case.id)])
              queryClient.invalidateQueries([CASES_QUERY_KEY])
              onClose()
              setIsLoading(false)
              return { title: `${userName} pasó a ser el responsable del caso` }
            },
            error: (err) => {
              setIsLoading(false)
              return { title: error.getErrorMessage(err) }
            },
          },
        )
      }
    },
    [props.case.id, targetProcessor],
  )

  const collaborators = props.case.caseRequest.users
  const currentProcessor = props.case.caseRequest.processor

  return (
    <Box
      display='flex'
      alignItems='center'
      experimental_spaceX={4}
      position={'relative'}
    >
      <UserDisplayer
        user={props.case.caseRequest.processor}
        avatarSize='md'
        title='Tramitador'
      />
      <Popover isOpen={isOpenPopover} onClose={onToggle} placement='bottom'>
        <PopoverTrigger>
          <Tooltip label='Ver colaboradores de la solicitud'>
            <Button
              variant={'unstyled'}
              size={'xs'}
              position={'absolute'}
              left={'105px'}
              top={'-2px'}
              onClick={onToggle}
            >
              <FaUsers />
            </Button>
          </Tooltip>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Colaboradores</PopoverHeader>
          <PopoverBody>
            <Box display={'flex'} flexDir={'column'} p={2} gap={4}>
              {collaborators.length ? (
                collaborators.map((collaborator, index) => {
                  return <UserDisplayer key={index} user={collaborator} />
                })
              ) : (
                <Text>Sin colaboradores</Text>
              )}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      {canUpdate && (
        <Tooltip label='Asignar tramitador'>
          <Button
            onClick={() => setOpen(true)}
            size='xs'
            position={'absolute'}
            right={'-2'}
            top={'-1'}
            variant='unstyled'
          >
            <FaExchangeAlt />
          </Button>
        </Tooltip>
      )}
      <Modal size='3xl' isOpen={isOpen} onClose={() => setOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Asignar tramitador</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <UsersSelectSearchable
              filter={(user) =>
                auth.id !== user.id && user.id !== currentProcessor?.id
              }
              onSelect={(user) => setTargetProcessor(user)}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              type='submit'
              mr={3}
              onClick={onAssignProcessor(() => setOpen(false))}
            >
              Asignar
            </Button>
            <Button variant='ghost'>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}
