import { UseFieldArrayAppend } from 'react-hook-form';
import { IFilter, IFiltersForm } from '../components/TableAbstract';

export const handleSortColumn = (
  filters: IFilter[],
  removeFilter: (index: number) => void,
  addFilter: UseFieldArrayAppend<IFiltersForm, 'filters'>
) => {
  return (headerId: string) => {
    const filterSortToBeRemovedIndex = filters.findIndex(
      (f) => f.type === 'sort'
    );

    const filterSortToBeRemoved = filters[filterSortToBeRemovedIndex];

    if (filterSortToBeRemovedIndex !== -1) {
      removeFilter(filterSortToBeRemovedIndex);
    }

    if (
      filterSortToBeRemoved?.value === 'DESC' &&
      filterSortToBeRemoved?.column === headerId
    ) {
      return;
    }

    addFilter({
      column: headerId,
      label: headerId,
      type: 'sort',
      disabledRender: true,
      value:
        filterSortToBeRemoved?.column === headerId &&
        filterSortToBeRemoved?.value === 'ASC'
          ? 'DESC'
          : 'ASC',
    });
  };
};
