import { IArtifactAck } from 'kach-clients'
import { IFileUploadV2 } from '../hooks/useFileUploadV2'

export const remapFileList = (fileList: IFileUploadV2[]): IArtifactAck[] =>
  fileList.map((f) => remapFile(f))

export const remapFile = (file: IFileUploadV2): IArtifactAck => {
  return {
    ...file,
    tags: (file?.tags || []).map((t) => {
      return {
        id: t.id,
      }
    }),
  }
}
