import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import { mode, GlobalStyleProps } from '@chakra-ui/theme-tools'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

export const primaryColor = '#466567'
export const secondaryColor = '#2E32AB'
export const textPrimary = '#33475b'
export const bgPrimary = '#33475b'

export const customTheme = extendTheme({
  styles: {
    global: (props: GlobalStyleProps) => ({
      body: {
        color: mode(textPrimary, 'whiteAlpha.900')(props),
      },
    }),
  },
  fonts: {
    body: 'Inter var,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
    heading:
      'Inter var,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
  },
  colors: {
    primary: primaryColor,
    secondary: secondaryColor,
    brandFull: {
      50: primaryColor,
      100: primaryColor,
      200: primaryColor,
      300: primaryColor,
      400: primaryColor,
      500: primaryColor,
      600: primaryColor,
      700: primaryColor,
      800: primaryColor,
      900: primaryColor,
    },
    'secondary-brandFull': {
      50: secondaryColor,
      100: secondaryColor,
      200: secondaryColor,
      300: secondaryColor,
      400: secondaryColor,
      500: secondaryColor,
      600: secondaryColor,
      700: secondaryColor,
      800: secondaryColor,
      900: secondaryColor,
    },
    brand: {
      '50': '#F0F4F5',
      '100': '#D4E1E2',
      '200': '#B9CED0',
      '300': '#9EBBBD',
      '400': '#83A8AA',
      '500': '#679598',
      '600': '#537779',
      '700': '#3E595B',
      '800': '#293C3D',
      '900': '#151E1E',
    },
    'secondary-brand': {
      50: '#eaeaff',
      100: '#c2c4f4',
      200: '#9a9de6',
      300: '#7276da',
      400: '#4a4ece',
      500: '#3135b5',
      600: '#25298e',
      700: '#191d66',
      800: '#0d1240',
      900: '#03051b',
    },
    'bg-primary-scheme': {
      '50': '#EFF2F6',
      '100': '#D1DBE5',
      '200': '#B4C4D5',
      '300': '#96ADC4',
      '400': '#7996B4',
      '500': '#5C7FA3',
      '600': '#496683',
      '700': '#374C62',
      '800': '#253341',
      '900': '#121921',
    },
    textPrimary,
    bgPrimary,
    link: '#6B4AD9',
    'light.grey': '#fafafa',
    'dark.grey': '#8f9896',
    'body.grey': 'rgba(252,252,252)',
    'black.opacity': '#000000cc',
    'white.opacity': '#ffffffcc',
  },
  shadows: {
    outline: 'none',
  },
  fontSizes: {
    xxs: '0.55rem',
  },
  container: {
    standard: '1152px',
  },
  components: {
    Editable: {
      variants: {
        bold32: {
          preview: {
            fontSize: '32px',
            lineHeight: '36px',
            fontWeight: '700',
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        label: {
          touchAction: 'none',
        },
      },
    },
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: 'none',
        },
      },
    },
    ModalCloseButton: {
      baseStyle: {
        _hover: {
          background: 'transparent',
        },
      },
    },
    Switch: {
      defaultProps: {
        focusBorderColor: 'primary',
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: 'primary',
      },
    },
    ModalContent: {
      baseStyle: {
        borderRadius: 'md',
        overflow: 'hidden',
        boxShadow: '2xl',
        backdropFilter: 'saturate(180%) blur(20px)',
      },
    },
    Button: {
      variants: {
        primary: {
          background: 'primary',
          color: 'white',
        },
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: 'primary',
      },
    },
    Select: {
      defaultProps: {
        focusBorderColor: 'primary',
      },
    },
  },
  config,
})
