import { IDynamicObject } from '../components/InvestigationLine'

export const removeFiltersMetadata = (filters: IDynamicObject = {}) =>
  Object.fromEntries(
    Object.entries(filters).map(
      ([c, v]: [string, string | { name: string }[]]) => {
        return [
          c,
          typeof v === 'string' || (typeof v === 'object' && !Array.isArray(v))
            ? v
            : (v as Array<{ name: string } | number>).map((ele) =>
                typeof ele === 'number' ? ele : ele.name,
              ),
        ]
      },
    ),
  )
