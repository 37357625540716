import { IPermission } from 'kach-clients'

export const hasPermission = (
  permissions: IPermission[],
  want: Pick<IPermission, 'resource' | 'action'>,
) =>
  !!permissions.find(
    (permission) =>
      permission.action === want.action &&
      permission.resource === want.resource,
  )
