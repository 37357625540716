import { ChakraProvider } from '@chakra-ui/react'
import { authAtom } from 'atoms/auth.atom'
import { notificationsAtom } from 'atoms/notifications.atom'
import { es } from 'date-fns/locale'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import { Provider, useAtom } from 'jotai'
import { ErrorBoundary, KachProvider } from 'kach-components'
import type { AppProps } from 'next/app'
import NextNprogress from 'nextjs-progressbar'
import React, { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  QueryClient,
  QueryClientProvider,
  defaultContext,
} from '@tanstack/react-query'
import 'styles/globals.css'
import { customTheme } from 'styles/theme'
import { jobsAtom } from 'atoms/jobs.atom'
import Link from 'next/link'
import Head from 'next/head'

setDefaultOptions({ locale: es })

export const GLOBAL_SCOPE_JOTAI = 'global'

// content
const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
)

const AtomsRefreshers: React.FC<React.PropsWithChildren<{ pageProps: any }>> = (
  props,
) => {
  const [_, setAuth] = useAtom(authAtom, GLOBAL_SCOPE_JOTAI)
  const [__, setNotifications] = useAtom(notificationsAtom, GLOBAL_SCOPE_JOTAI)
  const [___, setJobs] = useAtom(jobsAtom, GLOBAL_SCOPE_JOTAI)

  useEffect(() => {
    setAuth(props.pageProps?.auth)
    setNotifications(props.pageProps?.notifications)
    setJobs({ jobs: props.pageProps?.jobs })
  }, [props.pageProps])

  return <>{props.children}</>
}

export default function App({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchIntervalInBackground: false,
          },
        },
      }),
  )

  const [showDevtools, setShowDevtools] = React.useState(false)

  React.useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  console.log('started')

  return (
    <ChakraProvider theme={customTheme}>
      <ErrorBoundary Navigator={Link}>
        <Provider
          scope={GLOBAL_SCOPE_JOTAI}
          initialValues={[
            [authAtom, pageProps?.auth],
            [notificationsAtom, pageProps?.notifications],
            [jobsAtom, { jobs: pageProps?.jobs }],
          ]}
        >
          <AtomsRefreshers pageProps={pageProps}>
            <DndProvider backend={HTML5Backend}>
              <KachProvider
                defaultContext={defaultContext}
                jobs={pageProps?.jobs || []}
                auth={pageProps?.auth}
              >
                <QueryClientProvider client={queryClient}>
                  <NextNprogress
                    options={{
                      showSpinner: false,
                    }}
                    color={'#475465'}
                  />
                  <Head>
                    <meta charSet='utf-8' />
                    <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
                    <meta
                      name='viewport'
                      content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
                    />
                    <meta name='description' content='Kach Lab' />

                    <link rel='icon' href='/kach-192x192.png' />

                    {/* Hotjar Tracking Code for Kach Insurer */}
                    <script
                      dangerouslySetInnerHTML={{
                        __html: `
                      (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:3296964,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `,
                      }}
                    />
                  </Head>
                  <Component {...pageProps} />
                  {/* <Toaster position='top-center' /> */}
                  {showDevtools && (
                    <React.Suspense fallback={null}>
                      <ReactQueryDevtoolsProduction />
                    </React.Suspense>
                  )}
                </QueryClientProvider>
              </KachProvider>
            </DndProvider>
          </AtomsRefreshers>
        </Provider>
      </ErrorBoundary>
    </ChakraProvider>
  )
}
