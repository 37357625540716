import { IUser } from 'kach-clients'

const KACH_SUPPORT_EMAIL = 'support@kach.com.ar'

export const buildCaseTimelineTrigger = (
  triggeredBy: Pick<IUser, 'firstName' | 'lastName' | 'email'>,
) => {
  if (!triggeredBy) return ''

  if (triggeredBy.email === KACH_SUPPORT_EMAIL) return 'Automáticamente se'

  return `${triggeredBy.firstName} ${triggeredBy.lastName}`
}
