import { CaseEvents, INewsRequest } from 'kach-clients'

export const newsRequestToEvent = (newsRequest: INewsRequest): CaseEvents => {
  return {
    updatedAt: newsRequest.createdAt,
    event: 'news_requested',
    createdAt: newsRequest.createdAt,
    details: [newsRequest],
    id: newsRequest.id,
    triggeredBy: newsRequest.user,
  }
}
