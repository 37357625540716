import { format } from 'date-fns'
import { es } from 'date-fns/locale'

export const formatDateToLocaleDays = (
  date?: Date | string,
  dateFormat = 'P',
) => {
  const dt = new Date(date)
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)

  return format(dtDateOnly, dateFormat)
}

export const formatUTC2UTC3 = (date: Date | string) => {
  if (!date) return ''

  const dt = new Date(date)
  const dtDateOnly = new Date(dt.valueOf() - 180 * 60 * 1000)

  return format(dtDateOnly, 'Pp')
}

export const formatDate = (
  date: string | Date,
  layout: string = 'Pp',
): string => {
  return format(new Date(date), layout, {
    locale: es,
  })
}
