export const getLastItem = <T>(array: T[]): T | undefined => {
  if (!array) {
    return;
  }

  if (!array.length) {
    return;
  }

  return array[array.length - 1];
};
