import { ICaseExtended } from 'kach-clients'
import { ActionsType, ResourcesType } from 'kach-constants'
import { useAuth } from './useAuth'

export const useCaseAbility = (
  caseData: ICaseExtended,
  considerStatus: boolean = true,
  action: ActionsType = 'update_all',
  resource: ResourcesType = 'case',
) => {
  const auth = useAuth()

  const isCaseMember = caseData.users?.some(
    (user) => user?.user?.id === auth.id,
  )

  const canUpdate = auth.role.permissions.some(
    (p) => p.action === action && p.resource === resource,
  )

  const hasRelationWithCase = !!canUpdate || !!isCaseMember

  const canPerformActions =
    !!hasRelationWithCase &&
    (!considerStatus ? true : caseData.status === 'in_progress')

  return {
    canPerformActions,
  }
}
