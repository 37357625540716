export const referenceEnumsToOpts = <T extends { name: string; id: number }>(
  enums: T[],
) => {
  return enums.map(
    (
      ele,
    ): {
      name?: string
      label?: string
      value: string | number
    } => ({
      value: ele.id,
      label: ele.name,
      name: ele.name,
    }),
  )
}
